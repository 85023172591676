
import React from 'react';
import './About.css';

function About() {
  return (
    <section className="About">
      <h3>About Me</h3>
      <p>
        I am a Data Engineer with experience in building scalable data pipelines and working with big data technologies.
      </p>
    </section>
  );
}

export default About;