import React from 'react';
import './Hero.css';

function Hero() {
  return (
    <section className="hero">
      <div className="hero-content">
        <h1>Welcome to My Portfolio</h1>
        <p>Hi, I'm Roshan Avadhesh Singh, a passionate developer.</p>
        <a href="/about" className="hero-button">
          Learn More
        </a>
      </div>
      <div className="scroll-down">
        <a href="#about">
          <img src="../Assets/down-arrow.png" alt="Scroll Down" />
        </a>
      </div>
    </section>
  );
}

export default Hero;
