import React from 'react';
import './Certifications.css';

function Certifications() {
  return (
    <section id="certifications">
      <h2>Certifications</h2>

      <div className="certification">
        <h3>Meta Database Engineer Certificate</h3>
        <p><strong>Issued by:</strong> Coursera</p>
        <p><strong>Authorized by:</strong> Meta</p>
        <p>
          The Meta Database Engineer Certificate is awarded to learners who complete a series of 
          courses on database engineering, hosted by Coursera. With a focus on MySQL, these rigorous, 
          self-paced courses, developed by Meta experts, prepare a candidate for an entry-level job 
          as a database engineer. The courses culminate in the successful completion of a capstone project.
        </p>
        <a
          href="https://www.credly.com/badges/42bb2f48-f68b-408d-9b61-bc7dee60a101/public_url"
          target="_blank"
          rel="noopener noreferrer"
        >
          View Certificate
        </a>
      </div>

      <div className="certification">
        <h3>GitHub Foundations</h3>
        <p><strong>Issued by:</strong> GitHub</p>
        <p>
          Passing the GitHub Foundations certification exam validates subject matter expertise by 
          measuring entry-level skills with GitHub basics like repositories, commits, branching, 
          markdowns, and project management.
        </p>
        <a
          href="https://www.credly.com/badges/ea27306b-c183-489d-9bdf-7ab4a4e237d4/public_url"
          target="_blank"
          rel="noopener noreferrer"
        >
          View Certificate
        </a>
      </div>

    </section>
  );
}

export default Certifications;
