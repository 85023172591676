import React from 'react';
import './Projects.css';

function Projects() {
  return (
    <section className="Projects">
      <h3>Projects</h3>
      <ul>
        <li>
          <h4>Little Lemon Restaurant Management System</h4>
          <p>
            This project is designed to manage the operations of the Little Lemon fast-food restaurant. 
            It is a part of the Meta Database Engineer Certificate course on Coursera. The project uses 
            MySQL for database modeling and Tableau for data analysis. The Preparation folder contains 
            all the initial files used to start working on this project.
          </p>
          <a
            href="https://github.com/roshanasingh4/db-capstone-project"
            target="_blank"
            rel="noopener noreferrer"
          >
            View Project
          </a>
        </li>
        <li>
          <h4>Entity-Relationship Diagram</h4>
          <p>
            This is a placeholder for the Entity-Relationship Diagram project. 
            It highlights the database schema and relationships for the Little Lemon Management System.
          </p>
          <a
            href="https://example.com/erd"
            target="_blank"
            rel="noopener noreferrer"
          >
            View ERD
          </a>
        </li>
        <li>
          <h4>Data Analysis with Tableau</h4>
          <p>
            This is a placeholder for the Data Analysis with Tableau project. 
            It provides insights such as customer sales, profit charts, and dashboards.
          </p>
          <a
            href="https://example.com/tableau-dashboard"
            target="_blank"
            rel="noopener noreferrer"
          >
            View Dashboard
          </a>
        </li>
      </ul>
    </section>
  );
}

export default Projects;
