
import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <section className="Contact">
      <h3>Contact</h3>
      <p>Email: roshan.singh@example.com</p>
    </section>
  );
}

export default Contact;