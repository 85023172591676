
import React from 'react';
import About from '../components/About';

function AboutPage() {
  return (
    <div>
      <About />
    </div>
  );
}

export default AboutPage;