
import React from 'react';
import Certifications from '../components/Certifications';

function CertificationsPage() {
  return (
    <div>
      <Certifications />
    </div>
  );
}

export default CertificationsPage;